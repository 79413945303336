<template>
	<div class="">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 问答标签
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="">
			<el-input placeholder="请输入标签名" v-model="query.name" style="width: 300px;">
				<template #append>
					<el-button icon="el-icon-search" @click="search"></el-button>
				</template>
			</el-input>
		</div>
		<div class="handle-box">
			<el-button @click="handleAdd">新增</el-button>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
			v-loading="tableLoading">
			<el-table-column prop="name" label="标签" align="center"></el-table-column>
			<el-table-column label="操作" width="180" align="center">
				<template #default="scope">
					<el-button type="text" icon="el-icon-edit" @click="del(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>
		<el-dialog title="添加问答标签" v-model="dialogVisible" width="30%">
			<el-form ref="form" :model="editQuery" label-width="100px">
				<el-form-item label="请输入标签名">
					<el-input v-model="editQuery.name" maxlength="5"  show-word-limit></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="confirm">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import request from '@/utils/request.js'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		reactive,
		ref,
		watch
	} from 'vue'
	export default {
		setup() {
			let query = reactive({
					page: 1,
					limit: 20,
					name: ''
				}),
				editQuery = reactive({
					name: ''
				}),
				tableLoading = ref(false),
				tableData = ref([]),
				pageTotal = ref(0),
				dialogVisible = ref(false);
			const handleAdd = () => {
				dialogVisible.value = true
			}
			const confirm = () => {
				request({
					url: '/admin/questionTag/create',
					method: 'post',
					data: {
						...editQuery
					},
				}).then((res) => {
					if (res.code == 200) {
						getList()
						dialogVisible.value = false
					} else {
						ElMessage.error(res.msg)
					}
				})
			}
			const getList = () => {
				tableLoading.value = true
				request({
					url: '/admin/questionTag/sysLists',
					method: 'get',
					params: {
						...query
					},
				}).then((res) => {
					tableLoading.value = false;
					if (res.code == 200) {
						tableData.value = res.data;
						pageTotal.value = res.total;
					}
				}).catch(() => {
					tableLoading.value = false;
				});
			}
			const handlePageChange = (val) => {
				query.page = val
				getList()
			}
			const del = (ids) => {
				ElMessageBox.confirm('此操作将永久删除该标签, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					request({
						url: '/admin/questionTag/delSys',
						method: 'DELETE',
						data: {
							ids
						},
					}).then((res) => {
						if (res.code == 200) {
							ElMessage.success("删除成功")
							getList()
							dialogVisible.value = false
						} else {
							ElMessage.error(res.msg)
						}
					})
				}).catch(() => {

				});
			}
			watch(
				() => dialogVisible.value,
				(val) => {
					if (!val) {
						editQuery.name = ""
					}
				}
			)
			const search = ()=> {
				query.page = 1
				getList()
			}
			getList()
			return {
				handleAdd,
				getList,
				query,
				tableLoading,
				tableData,
				dialogVisible,
				editQuery,
				pageTotal,
				handlePageChange,
				confirm,
				del,
				search
			}
		}
	}
</script>

<style scoped>
	.handle-box {
		margin: 5px 0;
	}
</style>
